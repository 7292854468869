import styles from "./Completion.module.scss";
import advert from "../../../assets/missional-strengths-advert.png";
import LogoutLink from "../../logout/LogoutLink";

function Completion() {
  return (
    <div className="card">
      <div className="card-body">
        <div className="text-end">
          <LogoutLink />
        </div>
        <span
          className={`${styles.cardTitle} ${styles.prussian} card-title p-2`}
        >
          <div>Thank you for completing the missional strengths survey.</div>
          <div>Your free report is on its way!</div>
        </span>
        <div className="card-body p-5">
          <div className="row">
            <div className="col-12 col-md-4">
              <img
                src={advert}
                className="w-100"
                alt="Pages of the Missional Strength Profile Report"
              />
            </div>
            <div className="col-12 col-md-8">
              <div className={`${styles.tagline} ${styles.prussian}`}>
                Get to know your missional strengths
              </div>
              <div className={`${styles.tagline} ${styles.prussian}`}>
                Discover how you are designed for mission
              </div>
              <p className={styles.plugParagraph}>
                The Missional Strengths Profile explains how you are designed
                for mission, sent on assignment, and gifted for a reason by
                ranking your missional strengths result.
              </p>
              <p className="text-right">
                Check your <strong>inbox</strong> for your{" "}
                <strong>free report</strong> now!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Completion;
