import "./inputs.scss";
import { Field, useField } from "formik";

const GroupField = ({ choices, label, errors, touched, ...props }) => {
  const [, , helpers] = useField(props);
  const { setTouched } = helpers;

  return (
    <div key="choices" role="group" aria-labelledby={props.id}>
      {choices.map(({ label, value }) => {
        return (
          <label
            key={value}
            className={`mb-4${choices.length > 2 ? " d-block" : ""}`}
          >
            <Field
              type={props.type}
              name={props.name}
              value={value}
              className="m-2"
              onBlur={(event) => {
                setTouched(true, true);
              }}
            />
            {label}
          </label>
        );
      })}
      {errors &&
        touched &&
        errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </div>
  );
};

export default GroupField;
