import styles from "./Registration.module.scss";

import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import debounce from "lodash.debounce";

import { Formik, Form } from "formik";

import FormCard from "../../formcard/FormCard";
import InputField from "../../inputs/InputField";
import CheckboxField from "../../inputs/CheckboxField";

import { validateIsFilled, validateEmail, isRequired } from "../../../utils";
import { register } from "../../../services/SessionService";
import settings from "../../../settings";
import useAuth from "../../../hooks/useAuth";

function Registration() {
  const [isSubmittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const { userId, handleLogin } = useAuth();

  const handleRegistration = debounce(
    async (formData, actions) => {
      actions.setSubmitting(true);
      setFailureMessage(); // Clear the failure message

      register(formData)
        .then((data) => {
          actions.setSubmitting(false);

          if (data.status === "success") {
            handleLogin(data.data.userId);
            setSubmittedSuccessfully(true);
          } else if (data.status === "error") {
            actions.setErrors(data.errors);
          } else if (data.status === "failure") {
            actions.setSubmitting(false);
            setSubmittedSuccessfully(false);
            setFailureMessage(data.message);
          }
        })
        .catch(() => {
          actions.setSubmitting(false);
          setSubmittedSuccessfully(false);
          setFailureMessage(
            `We could not process your registration. Please try again later or contact support at ${settings.supportEmail}.`
          );
        });
    },
    1000,
    { maxWait: 1000 }
  );

  const termsLine = (
    <>
      I have read and understand the{" "}
      <a
        href="https://missional.international/missionality/terms-of-service/"
        target="_blank"
        rel="noreferrer"
      >
        Terms & Conditions
      </a>{" "}
      of this agreement and{" "}
      <a
        href="https://missional.international/missionality/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{" "}
      and understand some of the questions may refer to sensitive data.
    </>
  );

  return (
    <>
      {(userId !== null || isSubmittedSuccessfully) && (
        <Navigate replace to="/survey" />
      )}
      <div className="container">
        <Formik
          initialValues={{
            username: "",
            password1: "",
            password2: "",
            email: "",
            first_name: "",
            last_name: "",
          }}
          onSubmit={handleRegistration}
        >
          {(props) => (
            <Form>
              <div className="row">
                <div className={`col-12 col-md-6 ${styles.advert}`}>
                  <h1>
                    The Missional International Missional Strengths Survey
                  </h1>
                  <span className={styles.tagline}>
                    Discover how God designed you
                  </span>
                  <h2 className="mt-4">Why take the Survey?</h2>
                  <p>
                    Join thousands of Christian believers who have taken the
                    only biblically-based self-assessment on missional
                    strengths. Take this simple, 20 minute missional strengths
                    survey and discover how you are designed for mission, sent
                    on assignment, and gifted for a reason. Knowing and using
                    your missional strengths enables you to join God at work in
                    His mission in your family, neighborhood and community.
                  </p>
                  <ul>
                    <li>Identify your purpose</li>
                    <li>Stoke your passion</li>
                    <li>Foster leadership skills</li>
                    <li>Discover God's priorities for you</li>
                    <li>Develop your unique life strategy</li>
                    <li>Identify potential conflict sources</li>
                  </ul>
                  <p>
                    God intends for you to find fulfillment in your relationship
                    with Him. Learn how to draw closer to God so that your
                    desires will become those God designed just for you!
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <FormCard
                    title="Register to take the Missional International Survey"
                    buttonLabel="Register now"
                    failureMessage={failureMessage}
                    otherChildren={
                      <Link to="/login">
                        Already have an account? Click here to login!
                      </Link>
                    }
                    {...props}
                  >
                    <p>
                      You must be 16 years or older to register for an account.
                      Children under 16 must have an adult register here for
                      them to take a survey.
                    </p>

                    <InputField
                      id="username"
                      name="username"
                      label="User name"
                      requiredStyle={true}
                      validate={validateIsFilled("This field is required")}
                      className="form-control"
                      errors={props.errors.username}
                      touched={props.touched.username}
                    />

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <InputField
                          id="first_name"
                          name="first_name"
                          label="First name"
                          requiredStyle={true}
                          validate={validateIsFilled("This field is required")}
                          className="form-control"
                          errors={props.errors.first_name}
                          touched={props.touched.first_name}
                        />
                      </div>

                      <div className="col-12 col-sm-6">
                        <InputField
                          id="last_name"
                          name="last_name"
                          label="Last name"
                          requiredStyle={true}
                          validate={validateIsFilled("This field is required")}
                          className="form-control"
                          errors={props.errors.last_name}
                          touched={props.touched.last_name}
                        />
                      </div>
                    </div>

                    <InputField
                      id="email"
                      name="email"
                      label="Email address"
                      requiredStyle={true}
                      validate={isRequired(
                        "This field is required",
                        validateEmail("The email address is not valid.")
                      )}
                      className="form-control"
                      errors={props.errors.email}
                      touched={props.touched.email}
                    />

                    <InputField
                      id="password1"
                      name="password1"
                      label="Password"
                      type="password"
                      requiredStyle={true}
                      validate={validateIsFilled("This field is required")}
                      className="form-control"
                      errors={props.errors.password1}
                      touched={props.touched.password1}
                    />

                    <InputField
                      id="password2"
                      name="password2"
                      label="Password (again)"
                      type="password"
                      requiredStyle={true}
                      validate={validateIsFilled("This field is required")}
                      help_text="Please re-enter your password"
                      className="form-control"
                      errors={props.errors.password2}
                      touched={props.touched.password2}
                    />

                    <CheckboxField
                      id="readAndUnderstand"
                      name="readAndUnderstand"
                      label={termsLine}
                      requiredStyle={true}
                      validate={validateIsFilled(
                        "You must agree to the terms & conditions and privacy policy"
                      )}
                      errors={props.errors.readAndUnderstand}
                      touched={
                        props.touched.readAndUnderstand || props.submitCount
                      }
                    />
                  </FormCard>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Registration;
