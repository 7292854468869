import React, { useState, useEffect } from "react";

import useAuth from "../../../hooks/useAuth";
import { useSurveyService } from "../../../services/SurveyService";

import Question from "../../question/Question";
import Spinner from "../../spinner/Spinner";

function Response(props) {
  const [requesting, setRequesting] = useState(true);
  const [remaining, setRemaining] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const authHandlers = useAuth();
  const { getRemainingQuestions, submitAnswer } =
    useSurveyService(authHandlers);

  useEffect(() => {
    remaining === undefined &&
      getRemainingQuestions(props.responseId)
        .then((response) => {
          setRequesting(false);
          setRemaining(response.remaining);
        })
        .catch((data) => {
          setRequesting(false);
          setErrorMessage(data.message);
        });
  });

  const popQuestion = () => {
    setRemaining(remaining.slice(1));
  };

  if (requesting) {
    return <Spinner />;
  }

  if (remaining?.length > 0) {
    return (
      <Question
        responseId={props.responseId}
        popQuestion={popQuestion}
        submitAnswer={submitAnswer}
        {...remaining[0]}
      />
    );
  }

  if (errorMessage) {
    return (
      <div className="errors">
        <div className="validation-error">{errorMessage}</div>
      </div>
    );
  }

  props.callback();
  return <Spinner />;
}

export default Response;
