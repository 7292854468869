/*
 * JavaScript function to get cookie by name; retrieved from
 * https://docs.djangoproject.com/en/3.1/ref/csrf/
 */
export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken"));
  return headers;
};

export const handleResponse = ({
  getUserInfo,
  handleLogin,
  handleLogout,
  isLoggedIn,
  setUserId,
}) => {
  return async (response) => {
    let result = {};
    let failureData = {
      status: "failure",
      message:
        "Please wait a moment and try again. If necessary, you can log back in and resume completing this survey later.",
    };

    try {
      result = await response.json();
    } catch (error) {
      return Promise.reject(failureData);
    }

    if (response.ok) {
      return Promise.resolve(result);
    }

    if (response.status === 401 || response.status === 403) {
      try {
        await isLoggedIn();
      } catch (errorMessage) {
        handleLogout();
        return Promise.reject(result);
      }

      let userInfo;

      try {
        userInfo = await getUserInfo();

        if (userInfo.id === undefined) {
          return Promise.reject(failureData);
        }
      } catch (errorMessage) {
        return Promise.reject(failureData);
      }

      setUserId(userInfo.id); // Update the user id in case it changed
    }

    return Promise.reject(result);
  };
};
