import "./ShoppingCart.scss";
import CartItem from "../cartitem/CartItem";

function ShoppingCart(props) {
  const total =
    props.items
      .map((item) => parseFloat(item.adjusted_price))
      .reduce((a, b) => a + b, 0) + props.donation;

  const removeItem = (id) => {
    props.setIds(
      props.items.map((item) => item.id).filter((itemId) => itemId !== id)
    );
  };

  const itemCount = props.items.length + !!props.donation;

  return (
    <div>
      {itemCount === 0 ? (
        <div>Your cart is empty.</div>
      ) : (
        <>
          <div>
            {props.items.map((item) => {
              return (
                <CartItem key={item.id} removeItem={removeItem} {...item} />
              );
            })}
            {!!props.donation && (
              <CartItem
                key="donation"
                name="Donation"
                adjusted_price={props.donation}
                disabled={true}
                adjustments={[]}
              />
            )}
          </div>
          <div className="row">
            <div className="col-7">
              <strong className="float-start">
                Total ({itemCount} item
                {itemCount === 1 ? "" : "s"}):
              </strong>
            </div>
            <div className="col-5">
              <strong className="float-end me-4">{total.toFixed(2)} USD</strong>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ShoppingCart;
