import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useLocalStorage("userId", null);
  const navigate = useNavigate();

  const value = useMemo(() => {
    const handleLogin = async (loggedInUserId) => {
      setUserId(loggedInUserId);
      navigate("/");
    };

    const handleLogout = () => {
      setUserId(null);
      navigate("/login", { replace: true });
    };

    return { userId, handleLogin, handleLogout, setUserId };
  }, [userId, navigate, setUserId]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
