import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Checkout from "./components/pages/checkout/Checkout";
import Completion from "./components/pages/completion/Completion";
import Survey from "./components/pages/survey/Survey";
import Login from "./components/pages/login/Login";
import Registration from "./components/pages/registration/Registration";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import { AuthProvider } from "./hooks/useAuth";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <div className="container">
            <Routes>
              <Route path="/" element={<Registration />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route
                path="/checkout"
                element={
                  <AuthenticatedRoute>
                    <Checkout />
                  </AuthenticatedRoute>
                }
              ></Route>
              <Route
                path="/completion"
                element={
                  <AuthenticatedRoute>
                    <Completion />
                  </AuthenticatedRoute>
                }
              ></Route>
              <Route
                path="/survey"
                element={
                  <AuthenticatedRoute>
                    <Survey />
                  </AuthenticatedRoute>
                }
              ></Route>
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
