import { useState } from "react";
import { useField } from "formik";
import { purchaseCodehasValidFormat } from "../../utils";
import "./inputs.scss";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { ReactComponent as X } from "../../assets/icons/close-circle.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus-circle.svg";
import settings from "../../settings";

const PurchaseCodesField = (props) => {
  const [nextPurchaseCode, setNextPurchaseCode] = useState("");
  const [isValidating, setIsValidating] = useState(false);

  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched, setError } = helpers;
  const currentValue = field.value || [];
  const validatePurchaseCode = async (code) => {
    setIsValidating(true);
    const errorMessage = "The purchase code is invalid.";

    if (!purchaseCodehasValidFormat(code)) {
      setIsValidating(false);
      return Promise.reject(errorMessage);
    }

    try {
      const result = await props.lookupPurchaseCode(code);

      setIsValidating(false);
      if (!(result.status === "success")) {
        return Promise.reject(errorMessage);
      }

      return Promise.resolve(result.data);
    } catch (error) {
      setIsValidating(false);
      if (error.status === "failure") {
        return Promise.reject(
          `The purchase code could not be validated. Please reload the page or contact ${settings.supportEmail}.`
        );
      }
      return Promise.reject(errorMessage);
    }
  };

  const updateNextPurchaseCode = (event) => {
    setTouched(true, true);
    setNextPurchaseCode(event.target.value);
  };

  const addPurchaseCode = (event) => {
    const code = nextPurchaseCode.toUpperCase();
    setTouched(true, false);

    if (!code) {
      setError(["Type something in the box above to add a purchase code"]);
      return;
    }

    if (currentValue.map((item) => item.purchase_code).includes(code)) {
      setError(["You already applied that code!"]);
      return;
    }

    validatePurchaseCode(code)
      .then((data) => {
        currentValue.push(data);
        setValue(currentValue, true);
        setNextPurchaseCode("");
        props.onChange(currentValue);
      })
      .catch((error) => {
        !!error && setError([error]);
      });
  };

  const codeList = currentValue.map((codeData) => {
    return (
      <Badge
        pill
        key={`purchaseCode-${codeData.purchase_code}-code`}
        bg="secondary"
        className="my-2 me-2 "
      >
        <span className="mx-1 align-middle">{codeData.purchase_code}</span>
        <Button
          key={`purchaseCode-${codeData.purchase_code}-removeButton`}
          variant="secondary"
          className="p-0 btn-sm"
          aria-label="Remove purchase code"
          autoComplete="off"
          disabled={!!props.isSubmitting || !!props.disabled || isValidating}
          onClick={(event) => {
            const nextValue = currentValue.filter((item) => {
              return item.purchase_code !== codeData.purchase_code;
            });
            setValue(nextValue, true);
            props.onChange(nextValue);
          }}
        >
          <X />
        </Button>
      </Badge>
    );
  });

  return (
    <div
      key={props.id}
      className={props.errors && props.touched ? "errors" : ""}
    >
      <label
        key="label"
        htmlFor={props.id}
        className={"my-2" + (props.requiredStyle ? " required" : "")}
      >
        <span className="label-text">{props.label}</span>
      </label>

      <div>{codeList}</div>
      <div className="row">
        <div className="col-12 col-md-8">
          <span className="d-inline-block my-2 me-2 w-100">
            <input
              id={`${props.id}-newCode`}
              type="text"
              onChange={updateNextPurchaseCode}
              onBlur={updateNextPurchaseCode}
              value={nextPurchaseCode}
              maxLength="6"
              minLength="6"
              placeholder="Example: XXXXXX"
              className="form-control"
              disabled={
                !!props.isSubmitting || !!props.disabled || isValidating
              }
            />
            {props.errors &&
              props.touched &&
              props.errors.map((error) => {
                return (
                  <div key={error} className="validation-error">
                    {error}
                  </div>
                );
              })}
          </span>
        </div>
        <div className="col-12 col-md-4">
          <Button
            variant="primary"
            autoComplete="off"
            disabled={!!props.isSubmitting || !!props.disabled || isValidating}
            onClick={addPurchaseCode}
            className="my-2 align-top float-end"
            aria-label="Add purchase code"
          >
            <Plus />
            <span className="mx-1">Add</span>
          </Button>
        </div>
      </div>

      <div>
        <small>{props.help_text}</small>
      </div>
    </div>
  );
};

export default PurchaseCodesField;
