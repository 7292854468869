import Button from "react-bootstrap/Button";
import { ReactComponent as X } from "../../assets/icons/close-circle.svg";

function CartItem(props) {
  const buildAdjustments = (adjustments) => {
    const output = [];
    let index = 0;
    for (const adjustment of adjustments) {
      let amount = parseFloat(adjustment.amount).toFixed(2);
      output.push(
        <div
          key={`product-${props.id}-adjustment-${index}`}
          className={"row" + (amount < 0 ? " aqua-text" : "")}
        >
          <div className="col-7">
            <div className="mx-4 w-100">
              <div className="cart-item-name">{adjustment.name}</div>
            </div>
          </div>
          <div className="col-5">
            <div className="float-end me-4">
              {amount > 0 ? "+" : ""}
              {amount} USD
            </div>
          </div>
        </div>
      );
      index += 1;
    }
    return output;
  };

  return (
    <div className="cart-item my-3">
      {props.adjustments.length &&
      props.adjusted_price < props.adjusted_base_price ? (
        <>
          <div className="row">
            <div className="col-7">{props.name}</div>
            <div className="col-5">
              <div className="float-end">
                <s className="gray-text">
                  {parseFloat(props.adjusted_base_price).toFixed(2)} USD
                </s>
                <Button
                  key={`cartItem-${props.id}-removeButton`}
                  variant="link"
                  className="p-0 btn-sm"
                  aria-label="Remove cart item"
                  autoComplete="off"
                  disabled={parseFloat(props.adjusted_base_price) === 0}
                  onClick={(event) => {
                    props.removeItem(props.id);
                  }}
                >
                  <X className="black-text" />
                </Button>
              </div>
            </div>
          </div>
          {buildAdjustments(props.adjustments)}
          <div className="row">
            <div className="col-7">
              <strong className="cart-item-name ms-4">Subtotal</strong>
            </div>
            <div className="col-5">
              <strong className="float-end me-4">
                {parseFloat(props.adjusted_price).toFixed(2)} USD
              </strong>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-7">
            <div className="cart-item-name w-100">{props.name}</div>
          </div>
          <div className="col-5">
            <div className="float-end">
              {parseFloat(props.adjusted_price).toFixed(2)} USD
              <Button
                key={`cartItem-${props.id}-removeButton`}
                variant="link"
                className="p-0 btn-sm"
                aria-label="Remove cart item"
                autoComplete="off"
                disabled={
                  parseFloat(props.adjusted_base_price) === 0 || props.disabled
                }
                onClick={(event) => {
                  props.removeItem(props.id);
                }}
              >
                <X className="black-text" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartItem;
