import { Field } from "formik";
import "./inputs.scss";

const InputField = ({ label, errors, touched, requiredStyle, ...props }) => {
  return (
    <div key={props.id} className={errors && touched ? "errors" : ""}>
      <label
        key="label"
        htmlFor={props.id}
        className={"my-2" + (requiredStyle ? " required" : "")}
      >
        <span className="label-text">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </label>
      <Field {...props}></Field>
      {errors &&
        touched &&
        errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </div>
  );
};

export default InputField;
