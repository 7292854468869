import "./inputs.scss";
import { Field } from "formik";

const MultipleChoiceGridField = ({ errors, touched, ...props }) => {
  return (
    <Field name={props.id} validate={props.validate}>
      {({ field, form: { setFieldValue, validateField } }) => {
        const currentValue = field.value || {};
        const shouldDisable = (columnValue, rowValue) => {
          if (currentValue === undefined) {
            return false;
          }
          return !!Object.entries(currentValue).find(([rv, cv]) => {
            return cv === columnValue && rv !== rowValue;
          });
        };
        const shouldBeChecked = (columnValue, rowValue) => {
          return !!Object.entries(currentValue).find(([rv, cv]) => {
            return cv === columnValue && rv === rowValue;
          });
        };

        const desktopView = (id) => (
          <div className="multiple-choice-grid d-none d-md-table">
            <div className="thead d-table-row">
              <div className="d-table-cell"></div>
              {Object.entries(props.columns).map(([name, value]) => {
                return (
                  <div key={value} className="d-table-cell">
                    {name}
                  </div>
                );
              })}
            </div>
            {Object.entries(props.rows).map(([rowName, rowValue]) => {
              return (
                <div
                  key={rowName}
                  className={`choice-row d-table-row ${
                    !currentValue[rowValue] ? "error-row" : ""
                  }`}
                >
                  <div className="d-table-cell">{rowName}</div>
                  {Object.entries(props.columns).map(
                    ([columnName, columnValue]) => {
                      const handleChange = (event) => {
                        let value = currentValue || {};
                        value[rowValue] = columnValue;
                        setFieldValue(props.id, value, true);
                        validateField(props.id);
                      };

                      return (
                        <div
                          key={`${rowName}${columnName}`}
                          className="d-table-cell"
                        >
                          <input
                            name={`${id}${props.id}${rowValue}`}
                            type="radio"
                            onChange={handleChange}
                            onBlur={handleChange}
                            checked={shouldBeChecked(columnValue, rowValue)}
                            disabled={shouldDisable(columnValue, rowValue)}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          </div>
        );
        const mobileView = (id) => (
          <div className="multiple-choice-grid d-sm-table d-md-none">
            <div className="thead d-table-row">
              <div className="d-table-cell"></div>
              {Object.entries(props.rows).map(([name, value]) => {
                return (
                  <div
                    key={value}
                    className={`d-table-cell ${
                      !currentValue[value] ? "error-column" : ""
                    }`}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
            {Object.entries(props.columns).map(([columnName, columnValue]) => {
              return (
                <div key={columnName} className={`choice-row d-table-row `}>
                  <div className="d-table-cell text-start">{columnName}</div>
                  {Object.entries(props.rows).map(([rowName, rowValue]) => {
                    const handleChange = (event) => {
                      let value = currentValue || {};
                      value[rowValue] = columnValue;
                      setFieldValue(props.id, value, true);
                      validateField(props.id);
                    };

                    return (
                      <div
                        key={`${columnName}${rowName}`}
                        className={`d-table-cell ${
                          !currentValue[rowValue] ? "error-column" : ""
                        }`}
                      >
                        <input
                          name={`${id}${props.id}${columnValue}`}
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleChange}
                          checked={shouldBeChecked(columnValue, rowValue)}
                          disabled={shouldDisable(columnValue, rowValue)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );

        return (
          <>
            {desktopView("md")}
            {mobileView("sm")}
            {errors &&
              touched &&
              errors.map((error) => {
                return (
                  <div key={error} className="validation-error">
                    {error}
                  </div>
                );
              })}
          </>
        );
      }}
    </Field>
  );
};

export default MultipleChoiceGridField;
