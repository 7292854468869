import { useState } from "react";
import styles from "./LogoutLink.module.scss";
import Button from "react-bootstrap/Button";
import { ReactComponent as OutIcon } from "../../assets/icons/log-out.svg";

import useAuth from "../../hooks/useAuth";
import { logout } from "../../services/SessionService";

function LogoutLink(props) {
  const { handleLogout } = useAuth();
  const [error, setError] = useState(false);

  const doLogout = () => {
    logout()
      .then(() => {
        setError(false);
        handleLogout();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <>
      <Button
        variant="link"
        className={`${styles.prussianLink} px-0`}
        disabled={!!props.disabled}
        onClick={doLogout}
      >
        <OutIcon width="20" height="20" />
        <span className="mx-1">Logout</span>
      </Button>
      {!!error && (
        <div className={`m-0 errors ${styles.disappears}`}>
          <small>Try again</small>
        </div>
      )}
    </>
  );
}

export default LogoutLink;
