import { Field, useField } from "formik";
import "./inputs.scss";

const CheckboxField = ({ label, errors, touched, requiredStyle, ...props }) => {
  const [, , helpers] = useField(props);
  const { setTouched } = helpers;
  return (
    <div key={props.id} className={errors && touched ? "errors" : ""}>
      <label
        key="label"
        htmlFor={props.id}
        className={"my-2" + (requiredStyle ? " required" : "")}
      >
        <Field
          type="checkbox"
          onBlur={(event) => {
            setTouched(true, true);
          }}
          className="mr-2"
          {...props}
        ></Field>
        <span className="label-text p-2">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </label>
      {!!errors &&
        !!touched &&
        errors.map((error) => {
          return (
            <span key={error} className="d-inline-block validation-error">
              {error}
            </span>
          );
        })}
    </div>
  );
};

export default CheckboxField;
