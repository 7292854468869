import packageJson from "../package.json";

const settings = {
  baseApiUri: process.env.REACT_APP_BASE_API_URI || "http://localhost:10001/api",
  environment: process.env.NODE_ENV || "development",
  debug: process.env.REACT_APP_DEBUG === "true",
  supportEmail:
    process.env.REACT_APP_SUPPORT_EMAIL || "support-email@example.com.fake",
  version: packageJson.version,
  purchaseCodeAllowedChars: "6Y37XNRWPT9MFCH4",
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
};

export default settings;
