import "./inputs.scss";
import { Field } from "formik";

const SelectField = ({ choices, label, errors, touched, ...props }) => {
  return (
    <>
      <Field
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        className="form-control"
        validate={props.validate}
        as="select"
      >
        <option value="">--- {"Please select an answer"} ---</option>
        {choices.map(({ label, value }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </Field>

      {errors &&
        touched &&
        errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </>
  );
};

export default SelectField;
