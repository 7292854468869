import styles from "./FormCard.module.scss";

import { hasErrorAndIsTouched } from "../../utils";
import Button from "react-bootstrap/Button";

function FormCard(props) {
  const shouldShowFormErrorMessage = (props) => {
    if (props.submitCount < 1) {
      return false;
    }

    if (Object.keys(props.errors).length > 0) {
      return true;
    }

    if (hasErrorAndIsTouched(props.errors, props.touched)) {
      return true;
    }

    return false;
  };

  return (
    <div className={`${styles.card} card shadow bg-white`}>
      <span className={`${styles.cardTitle} card-title p-2`}>
        {props.title}
      </span>
      <div className={`${styles.cardBody} card-body`}>
        {props.children}

        <div className="feedback-area py-3">
          {shouldShowFormErrorMessage(props) && (
            <span className="errors">
              The form is not completed correctly. Please resolve the errors
              above and resubmit the form.
            </span>
          )}
          {!!props.failureMessage && (
            <span className="errors">{props.failureMessage}</span>
          )}
        </div>

        {props.otherChildren}
      </div>
      <Button
        type="submit"
        variant="primary"
        className={`mt-md-3 ${styles.btnWide} ${styles.btnAqua}`}
        autoComplete="off"
        disabled={!!props.isSubmitting}
      >
        {props.buttonLabel || "Submit"}
      </Button>
    </div>
  );
}

export default FormCard;
