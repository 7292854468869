import settings from "./settings";
import validator from "validator";
import { isPossiblePhoneNumber } from "react-phone-number-input/min";

export const validateDonationAmount = (value) => {
  if (!validator.isFloat(value)) {
    return "Please enter a number.";
  }
  if (!validator.isFloat(value, { gt: 0 })) {
    return "The amount must be greater than zero.";
  }
  if (+parseFloat(value).toFixed(2) !== parseFloat(value)) {
    return "Please enter a maximum of 2 digits after the decimal point.";
  }
};

export const purchaseCodehasValidFormat = (code) => {
  if (!code) {
    return false;
  }

  // The purchase code must the right length
  if (code.length !== 6) {
    return false;
  }

  // Every character must be an allowed character
  return code.split("").every((value) => {
    return settings.purchaseCodeAllowedChars.includes(value);
  });
};

export const validateEmail = (message) => {
  return (value) => {
    if (!validator.isEmail(value)) {
      return message;
    }
  };
};

export const validatePhone = (message) => {
  return (value) => {
    if (!isPossiblePhoneNumber(value)) {
      return message;
    }
  };
};

export const isRequired = (message, ...validators) => {
  return (text) => {
    if (!text) {
      return [message];
    }
    const errors = validators
      ?.map((validator) => {
        return validator(text);
      })
      .filter((error) => {
        return error;
      });

    if (errors.length > 0) {
      return errors;
    }
  };
};

export const notRequired = (...validators) => {
  return (text) => {
    if (text) {
      const errors = validators
        ?.map((validator) => {
          return validator(text);
        })
        .filter((error) => {
          return !!error;
        });

      if (errors.length > 0) {
        return errors;
      }
    }
  };
};

export const validateIsFilled = (message) => {
  return isRequired(message, () => {});
};

export const hasErrorAndIsTouched = (errors, touched) => {
  return (
    Object.entries(errors)
      .map(([key, value]) => {
        return touched[key];
      })
      .filter((value) => {
        return !!value;
      }).length > 0
  );
};

export const validateMultipleChoiceGrid = (rows, oneResponsePerColumn) => {
  return (value) => {
    // There must be a selection for each row
    const rowEntries = Object.entries(rows);
    let selectedColumns = [];
    let rowValue;

    for (let index = 0; index < rowEntries.length; index++) {
      [, rowValue] = rowEntries[index];

      if (value[rowValue] === undefined) {
        return ["Please make a selection for each item."];
      }

      if (oneResponsePerColumn && selectedColumns.includes(value[rowValue])) {
        return ["Each item may only be selected once."];
      }

      selectedColumns.push(value[rowValue]);
    }
  };
};

export const validatePersonAgeYear = (minAge, maxAge) => {
  const currentYear = new Date().getFullYear();

  return (value) => {
    if (value < currentYear - maxAge || value > currentYear - minAge) {
      return `The year is invalid.`;
    }
  };
};
