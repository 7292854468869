import "./inputs.scss";
import { useField } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";

const AsyncSelectField = ({ label, errors, touched, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched, setError } = helpers;

  const loadOptions = async (search, loadedOptions) => {
    const filters = await props.getAdditionalFilters();
    const resultsCount = loadedOptions.reduce(
      (total, group) => total + group.options.length,
      0
    );

    return props
      .getChoices({
        fieldName: props.choicesName,
        search: search,
        offset: resultsCount,
        ...filters,
      })
      .then((response) => {
        return {
          options: response.results,
          hasMore: !!response.next,
        };
      });
  };

  const handleChange = (selection) => {
    setTouched(true, true);
    setValue(selection.value, true);
  };

  const handleBlur = () => {
    setTouched(true, true);
    setError([]);
  };

  const reduceOptions = (previousOptions, loadedOptions) => {
    const reducedPreviousOptions = previousOptions.reduce((groups, item) => {
      const label = item.label || "";
      groups[label] = [...(groups[label] || []), ...item.options];
      return groups;
    }, {});

    const groupedLoadedOptions = loadedOptions.reduce((groups, item) => {
      const label = item.group || "";
      groups[label] = groups[label] || [];
      groups[label].push(item);
      return groups;
    }, reducedPreviousOptions);

    return Object.entries(groupedLoadedOptions).map(([label, options]) => {
      return {
        label: label,
        options: options,
      };
    });
  };

  return (
    <>
      <AsyncPaginate
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        className={props.className}
        loadOptions={loadOptions}
        defaultOptions
        cacheOptions
        onChange={handleChange}
        onBlur={handleBlur}
        reduceOptions={reduceOptions}
      />

      {errors &&
        touched &&
        errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </>
  );
};

export default AsyncSelectField;
