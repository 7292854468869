import { useState } from "react";
import { useField } from "formik";
import { validateDonationAmount, notRequired } from "../../utils";
import "./inputs.scss";
import Button from "react-bootstrap/Button";
import { ReactComponent as Plus } from "../../assets/icons/plus-circle.svg";
import { ReactComponent as X } from "../../assets/icons/close-circle.svg";

const DonationButton = (props) => {
  const amount = parseFloat(props.donationAmount).toFixed(2);
  const handleClick = () => {
    props.addDonationAmount(parseFloat(amount));
  };
  return (
    <Button
      variant="light"
      autoComplete="off"
      disabled={!!props.isSubmitting || !!props.disabled}
      onClick={handleClick}
      className="my-2 align-top float-end"
      aria-label={`Add donation of ${amount} ${props.donationCurrencyCode}`}
    >
      <span className="mx-1">
        {amount} {props.donationCurrencyCode}
      </span>
    </Button>
  );
};

const DonationField = (props) => {
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched, setError } = helpers;
  const currentValue = field.value || 0.0;
  const [customDonationAmount, setCustomDonationAmount] = useState("");
  const updateValue = (value) => {
    const fixedValue = +parseFloat(value).toFixed(2);
    setValue(fixedValue);
    props.onChange && props.onChange(fixedValue);
  };

  return (
    <div
      key={props.id}
      className={props.errors && props.touched ? "errors" : ""}
    >
      <label
        key="label"
        htmlFor={props.id}
        className={"my-2" + (props.requiredStyle ? " required" : "")}
      >
        <span className="label-text">{props.label}</span>
      </label>

      <div className="row">
        <div className="col-12">
          <strong>
            Your donation today: {parseFloat(currentValue).toFixed(2)}{" "}
            {props.donationCurrencyCode}
          </strong>
          {currentValue > 0 && (
            <Button
              key={`donation-${props.id}-resetButton`}
              variant="link"
              className="p-0 btn-sm"
              aria-label="Reset donation"
              autoComplete="off"
              disabled={currentValue <= 0}
              onClick={(event) => {
                updateValue(0);
              }}
            >
              <X className="black-text" />
            </Button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-3 col-md-3">
          <DonationButton
            donationAmount="5.0"
            donationCurrencyCode={props.donationCurrencyCode}
            addDonationAmount={updateValue}
            disabled={!!props.isSubmitting || !!props.disabled}
          />
        </div>
        <div className="col-3 col-md-3">
          <DonationButton
            donationAmount="10.0"
            donationCurrencyCode={props.donationCurrencyCode}
            addDonationAmount={updateValue}
            disabled={!!props.isSubmitting || !!props.disabled}
          />
        </div>
        <div className="col-3 col-md-3">
          <DonationButton
            donationAmount="25.0"
            donationCurrencyCode={props.donationCurrencyCode}
            addDonationAmount={updateValue}
            disabled={!!props.isSubmitting || !!props.disabled}
          />
        </div>
        <div className="col-3 col-md-3">
          <DonationButton
            donationAmount="50.0"
            donationCurrencyCode={props.donationCurrencyCode}
            addDonationAmount={updateValue}
            disabled={!!props.isSubmitting || !!props.disabled}
          />
        </div>
        <div className="col-12 col-md-12">
          You can select a custom donation amount below:
          <div className="input-group">
            <input
              id="customDonationAmount"
              type="text"
              placeholder="Other amount"
              className="form-control"
              disabled={!!props.isSubmitting || !!props.disabled}
              aria-describedby="customAmountButton"
              onChange={(event) => {
                setCustomDonationAmount(event.target.value);
                setTouched(true, true);
              }}
              onBlur={(event) => {
                setCustomDonationAmount(event.target.value);
                setTouched(true, true);
              }}
              value={customDonationAmount}
            />
            <div className="input-group-append">
              <Button
                id="customAmountButton"
                disabled={!!props.isSubmitting || !!props.disabled}
                onClick={() => {
                  const errors = notRequired(validateDonationAmount)(
                    customDonationAmount
                  );
                  setTouched(true, false);
                  if (errors) {
                    setError(errors);
                  }
                  if (!errors && customDonationAmount) {
                    updateValue(parseFloat(customDonationAmount));
                    setCustomDonationAmount("");
                  }
                }}
              >
                <Plus />
                <span className="mx-1">Add</span>
              </Button>
            </div>
          </div>
          {props.errors &&
            props.touched &&
            props.errors.map((error) => {
              return (
                <div key={error} className="validation-error">
                  {error}
                </div>
              );
            })}
        </div>
      </div>

      <div>
        <small>{props.help_text}</small>
      </div>
    </div>
  );
};

export default DonationField;
