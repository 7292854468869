import { Field } from "formik";
import "./inputs.scss";

const SurveyInputField = ({
  label,
  errors,
  touched,
  requiredStyle,
  ...props
}) => {
  return (
    <div className="my-3">
      <Field {...props}></Field>
      {errors &&
        touched &&
        errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </div>
  );
};

export default SurveyInputField;
