import settings from "../settings";
import { getHeaders } from "./utils";

export const getUserInfo = async () => {
  const response = await fetch(`${settings.baseApiUri}/session/user_info`, {
    method: "get",
    headers: getHeaders(),
  });
  const result = await response.json();

  if (result.status === "success") {
    return Promise.resolve(result.data);
  } else {
    return Promise.reject(result.message);
  }
};

export const register = async (data) => {
  const result = await fetch(`${settings.baseApiUri}/session/register`, {
    method: "post",
    body: JSON.stringify(data),
    headers: getHeaders(),
  });

  return result.json();
};

export const isLoggedIn = async () => {
  const response = await fetch(`${settings.baseApiUri}/session/is_loggedin`, {
    method: "get",
    headers: getHeaders(),
  });
  const result = await response.json();

  if (result.status === "success") {
    return Promise.resolve(result.message);
  } else {
    return Promise.reject(result.message);
  }
};

export const login = async ({ username, password }) => {
  const response = await fetch(`${settings.baseApiUri}/session/login`, {
    headers: getHeaders(),
    method: "post",
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  const result = await response.json();

  if (result.status === "success") {
    return Promise.resolve(result);
  } else {
    return Promise.reject(result);
  }
};

export const logout = async () => {
  const response = await fetch(`${settings.baseApiUri}/session/logout`, {
    headers: getHeaders(),
    method: "post",
  });

  if (response.status === 200) {
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
