import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/min";

import "./inputs.scss";
import { useField } from "formik";
import debounce from "lodash.debounce";

const PhoneNumberField = (props) => {
  const [, , helpers] = useField(props);
  const { setValue, setTouched } = helpers;

  const handleChange = (number) => {
    setValue(number, props.touched);
    return number;
  };

  return (
    <div
      key={props.id}
      className={
        "row mb-3 mx-0 " + (props.errors && props.touched ? "errors" : "")
      }
    >
      <PhoneInput
        name={props.name}
        className="px-0"
        placeholder={props.placeholder}
        value={props.defaultValue}
        onChange={handleChange}
        onBlur={(event) => {
          setTouched(true, true);
        }}
        onCountryChange={debounce((flagAlpha2) => {
          setTouched(true, true);
        }, 100)}
        initialValueFormat={props.initialValueFormat || "national"}
        limitMaxLength={true}
        addInternationalOption={false}
        defaultCountry="US"
        numberInputProps={{ className: "form-control" }}
        countryOptionsOrder={["US"]}
      />

      {props.errors &&
        props.touched &&
        props.errors.map((error) => {
          return (
            <div key={error} className="validation-error">
              {error}
            </div>
          );
        })}
    </div>
  );
};

export default PhoneNumberField;
