import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import debounce from "lodash.debounce";

import { Formik, Form } from "formik";

import FormCard from "../../formcard/FormCard";
import InputField from "../../inputs/InputField";

import { validateIsFilled } from "../../../utils";
import { login } from "../../../services/SessionService";
import settings from "../../../settings";
import useAuth from "../../../hooks/useAuth";

function Login() {
  const [isSubmittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const { userId, handleLogin } = useAuth();

  const handleSubmit = debounce(
    async (formData, actions) => {
      actions.setSubmitting(true);
      setFailureMessage(); // Clear the failure message

      login(formData)
        .then((data) => {
          actions.setSubmitting(false);

          if (data.status === "success") {
            handleLogin(data.data.userId);
            setSubmittedSuccessfully(true);
          } else if (data.status === "error") {
            actions.setErrors(data.errors);
          } else if (data.status === "failure") {
            actions.setSubmitting(false);
            setSubmittedSuccessfully(false);
            setFailureMessage(data.message);
          }
        })
        .catch((data) => {
          actions.setSubmitting(false);
          setSubmittedSuccessfully(false);

          if (data?.message) {
            setFailureMessage(data.message);
          } else {
            setFailureMessage(
              `We could not process your login. Please try again later or contact support at ${settings.supportEmail}.`
            );
          }
        });
    },
    1000,
    { maxWait: 1000 }
  );

  return (
    <>
      {(userId !== null || isSubmittedSuccessfully) && (
        <Navigate replace to="/survey" />
      )}
      <div className="container">
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <FormCard
                title={"Login to Survey"}
                buttonLabel="Login"
                failureMessage={failureMessage}
                otherChildren={
                  <Link to="/">
                    Don't have an account yet? Click here to register!
                  </Link>
                }
                {...props}
              >
                <InputField
                  id="username"
                  name="username"
                  label="User name"
                  requiredStyle={true}
                  validate={validateIsFilled("This field is required")}
                  className="form-control"
                  errors={props.errors.username}
                  touched={props.touched.username}
                />

                <InputField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  requiredStyle={true}
                  validate={validateIsFilled("This field is required")}
                  className="form-control"
                  errors={props.errors.password}
                  touched={props.touched.password}
                />
              </FormCard>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Login;
