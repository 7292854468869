import settings from "../settings";
import { getHeaders, handleResponse } from "./utils";
import { getUserInfo, isLoggedIn } from "./SessionService";

export const useCheckoutService = (authHandlers) => {
  const handle = handleResponse({ getUserInfo, isLoggedIn, ...authHandlers });

  return {
    availableProducts: async (purchaseCodes) => {
      return await handle(
        await fetch(`${settings.baseApiUri}/commerce/products`, {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify({
            purchase_codes: purchaseCodes,
          }),
        })
      );
    },
    orderProducts: async (orderInfo) => {
      return await handle(
        await fetch(`${settings.baseApiUri}/commerce/products/order`, {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(orderInfo),
        })
      );
    },
    lookupPurchaseCode: async (purchaseCode) => {
      return await handle(
        await fetch(
          `${settings.baseApiUri}/commerce/purchase_code/${purchaseCode}`,
          {
            method: "get",
            headers: getHeaders(),
          }
        )
      );
    },
  };
};
