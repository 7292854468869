import settings from "../settings";
import { getHeaders, handleResponse } from "./utils";
import { getUserInfo, isLoggedIn } from "./SessionService";

export const useSurveyService = (authHandlers) => {
  const handle = handleResponse({ getUserInfo, isLoggedIn, ...authHandlers });

  return {
    getIncompleteSurveys: async () => {
      return await handle(
        await fetch(`${settings.baseApiUri}/survey/survey/incomplete/`, {
          headers: getHeaders(),
          method: "get",
        })
      );
    },
    getIncompleteResponsesForSurvey: async (surveyId) => {
      return await handle(
        await fetch(
          `${settings.baseApiUri}/survey/response/?survey=${surveyId}&submitted=false`,
          {
            headers: getHeaders(),
            method: "get",
          }
        )
      );
    },
    createResponse: async (surveyId, respondentId) => {
      return await handle(
        await fetch(`${settings.baseApiUri}/survey/response/`, {
          headers: getHeaders(),
          method: "post",
          body: JSON.stringify({
            survey: surveyId,
            respondent: respondentId,
            answers: [],
          }),
        })
      );
    },
    getRemainingQuestions: async (id) => {
      return await handle(
        await fetch(`${settings.baseApiUri}/survey/response/${id}/remaining/`, {
          headers: getHeaders(),
          method: "get",
        })
      );
    },
    submitAnswer: async (responseId, questionId, value) => {
      return await handle(
        await fetch(`${settings.baseApiUri}/survey/answer/`, {
          headers: getHeaders(),
          method: "post",
          body: JSON.stringify({
            response: responseId,
            question: questionId,
            value: value,
          }),
        })
      );
    },
    submitResponse: async (responseId) => {
      return await handle(
        await fetch(
          `${settings.baseApiUri}/survey/response/submit/${responseId}`,
          {
            headers: getHeaders(),
            method: "post",
            body: JSON.stringify({
              submitted: true,
            }),
          }
        )
      );
    },
    getChoices: async ({ fieldName, ...filters }) => {
      const url =
        `${settings.baseApiUri}/survey/choices/${fieldName}/?` +
        new URLSearchParams(filters);

      return await handle(
        await fetch(url, {
          headers: getHeaders(),
          method: "get",
        })
      );
    },
    getAnswer: async (responseId, questionCode) => {
      const url =
        `${settings.baseApiUri}/survey/answer/?` +
        new URLSearchParams({ response: responseId, code: questionCode });

      return await handle(
        await fetch(url, {
          headers: getHeaders(),
          method: "get",
        })
      ).then((data) => {
        return data.results[0]?.value;
      });
    },
  };
};
