import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import useAuth from "../../../hooks/useAuth";

import debounce from "lodash.debounce";
import { Formik, Form } from "formik";
import PurchaseCodesField from "../../inputs/PurchaseCodesField";
import DonationField from "../../inputs/DonationField";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import LogoutLink from "../../logout/LogoutLink";
import Product from "../../product/Product";
import ShoppingCart from "../../shoppingcart/ShoppingCart";
import { ReactComponent as CartIcon } from "../../../assets/icons/shopping-cart.svg";
import { ReactComponent as Checkmark } from "../../../assets/icons/check.svg";
import Spinner from "../../spinner/Spinner";

import { useCheckoutService } from "../../../services/CheckoutService";
import settings from "../../../settings";

import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtons,
} from "@paypal/react-paypal-js";

function Checkout(props) {
  let navigate = useNavigate();
  const authHandlers = useAuth();
  const { availableProducts, orderProducts, lookupPurchaseCode } =
    useCheckoutService(authHandlers);
  const [selectedIds, setSelectedIds] = useState([]);
  const [products, setProducts] = useState(undefined);
  const [requestingProducts, setRequestingProducts] = useState(true);
  const [requestingError, setRequestingError] = useState(false);
  const [purchaseCodes, setPurchaseCodes] = useState([]);
  const [donation, setDonation] = useState(0);
  const { userId } = authHandlers;

  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);

  const getSelectedItems = () => {
    if (products === undefined) {
      return [];
    }
    return products.filter(
      (product) => !!selectedIds.find((id) => id === product.id)
    );
  };

  const loadAvailableProducts = (purchaseCodes) => {
    setRequestingProducts(true);
    setRequestingError(false);
    return availableProducts(purchaseCodes.map((code) => code.purchase_code))
      .then((data) => {
        setRequestingProducts(false);
        setRequestingError(false);
        setProducts(data.results);
        data.results
          .filter((item) => parseFloat(item.adjusted_price) === 0)
          .map((item) => item.id)
          .forEach(selectItem);
      })
      .catch((data) => {
        setRequestingProducts(false);
        setRequestingError(true);
      });
  };

  const getPurchaseCodeForProduct = (productId) => {
    return purchaseCodes.find((code) => code.product_id === productId);
  };

  useEffect(() => {
    products === undefined && loadAvailableProducts(purchaseCodes);
  });

  const handleNext = debounce(
    (formData, actions) => {
      actions.setSubmitting(true);

      orderProducts({
        items: getSelectedItems().map((item) => {
          return {
            product: item.id,
            respondent: userId,
            purchase_code: item.purchase_code,
          };
        }),
      })
        .then((data) => {
          actions.setSubmitting(false);
          navigate("/completion");
        })
        .catch((data) => {
          actions.setSubmitting(false);

          if (data.status === "error" && data.errors) {
            actions.setErrors(data.errors);
          } else {
            actions.setErrors({
              __all__: ["An error occurred while checking out."],
            });
            actions.setSubmitting(false);
          }
        });
    },
    1000,
    { maxWait: 1000 }
  );

  const selectItem = (id) => {
    if (!selectedIds.find((selectedId) => selectedId === id)) {
      setSelectedIds(selectedIds.concat([id]));
    }
  };

  const createPaypalOrder = (data, actions) => {
    /* This function sets up the details of the transaction. */
    return actions.order.create({
      intent: "AUTHORIZE",
      purchase_units: [
        {
          amount: {
            value: calculateTotal(getSelectedItems()).toFixed(2),
            currency_code: "USD",
          },
        },
      ],
    });
  };

  const onPaypalApprove = async (data, actions) => {
    /* Authorize the transaction */
    const orderId = data.orderID;
    const errorMessage = `An error occurred while ordering products. Please contact support at ${settings.supportEmail}.`;
    let details, authId;
    try {
      details = await actions.order.authorize();
    } catch (error) {
      return Promise.reject(error);
    }
    try {
      authId = details.purchase_units[0].payments.authorizations[0].id;
    } catch (error) {
      return Promise.reject({
        status: "error",
        message: errorMessage,
      });
    }

    try {
      await orderProducts({
        items: getSelectedItems().map((item) => {
          return {
            product: item.id,
            respondent: userId,
            purchase_code: item.purchase_code,
          };
        }),
        payment: {
          purchaser: userId,
          external_order_id: orderId,
          external_authorization_id: authId,
          payment_processor: "paypal",
          donation: donation,
        },
      });
    } catch (error) {
      const errorResponse = Promise.reject({
        status: "error",
        message: errorMessage,
      });
      setShowErrorModal(true);
      if (error.status === "error") {
        return errorResponse;
      } else if (error.status === "failure") {
        return errorResponse;
      }
    }
    navigate("/completion");

    return Promise.resolve(details);
  };

  const calculateTotal = (items) => {
    const total = items
      .map((item) => parseFloat(item.adjusted_price))
      .filter((price) => price > 0)
      .reduce((a, b) => a + b, 0);
    return parseFloat(total) + donation;
  };

  const ButtonWrapper = () => {
    const [{ isPending }] = usePayPalScriptReducer();
    return (
      <>
        {isPending ? <Spinner /> : null}
        <PayPalButtons
          createOrder={createPaypalOrder}
          onApprove={onPaypalApprove}
          forceReRender={[calculateTotal(getSelectedItems())]} // Force re-render when amount changes
        />
      </>
    );
  };

  return (
    <Formik
      initialValues={{ purchaseCodes: [], donation: 0.0 }}
      onSubmit={handleNext}
    >
      {(props) => (
        <>
          <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
            <Modal.Header closeButton>
              <Modal.Title>Order processing failed!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              We could not process your order. Please contact support at{" "}
              <a href={`mailto:${settings.supportEmail}`}>
                {settings.supportEmail}
              </a>
              .
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="checkout-card card">
            <div className="card-body">
              <div className="text-end">
                <LogoutLink disabled={!!props.isSubmitting} />
              </div>
              <h2>
                <CartIcon width={40} height={40} className="align-bottom" />{" "}
                Shopping cart
              </h2>
              <Form>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="my-3 p-0 px-md-4">
                      <h3>Products</h3>
                      {!requestingError && requestingProducts && <Spinner />}
                      {requestingError && !requestingProducts && (
                        <div className="errors">
                          <div className="validation-error">
                            The products list could not be (re)loaded. Please
                            reload the page or contact support at{" "}
                            <a href={`mailto:${settings.supportEmail}`}>
                              {settings.supportEmail}
                            </a>
                            .
                          </div>
                        </div>
                      )}
                      {!requestingError &&
                        !requestingProducts &&
                        (!products || !products.length) && (
                          <div className="card my-3">
                            <div className="card-body">
                              <div className="text-center">
                                No products available
                              </div>
                            </div>
                          </div>
                        )}
                      {!!products && (
                        <Accordion defaultActiveKey={0}>
                          {products.map((product, index) => {
                            const itemIsInCart = !!selectedIds.find(
                              (selectedId) => selectedId === product.id
                            );
                            const purchaseCode = getPurchaseCodeForProduct(
                              product.id
                            );
                            return (
                              <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>
                                  {product.name}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Product {...product} />
                                  <div className="d-inline-block w-100">
                                    {itemIsInCart ? (
                                      <span className="aqua-text float-end">
                                        <Checkmark />
                                        Added to order
                                      </span>
                                    ) : (
                                      <Button
                                        className="float-end"
                                        onClick={(event) => {
                                          selectItem(product.id);
                                        }}
                                        disabled={
                                          itemIsInCart || !!props.isSubmitting
                                        }
                                      >
                                        Add to order
                                      </Button>
                                    )}
                                  </div>
                                  <div className="d-inline-block w-100">
                                    {!!purchaseCode && itemIsInCart && (
                                      <span className="aqua-text float-end">
                                        <Checkmark />
                                        Applied purchase code{" "}
                                        {purchaseCode.purchase_code}
                                      </span>
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      )}
                    </div>
                    <div className="my-3 p-0 px-md-4">
                      <div className="card my-3">
                        <div className="card-body">
                          <DonationField
                            id="donation"
                            name="donation"
                            label={
                              <>
                                <h4>Make a Donation</h4>
                                <em>Support Missional Leaders Worldwide</em>
                                <p>
                                  Your support allows us to continue to offer
                                  resources either free or at drastically
                                  reduced rates worldwide. Thank you for helping
                                  people change lives by joining God at work in
                                  the world.
                                </p>
                              </>
                            }
                            donationCurrencyCode="USD"
                            errors={props.errors?.donation}
                            touched={props.touched?.donation}
                            onChange={setDonation}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="card my-3">
                      <div className="card-body">
                        <h3>Order ({selectedIds.length})</h3>
                        <ShoppingCart
                          items={getSelectedItems()}
                          setIds={setSelectedIds}
                          products={products}
                          donation={donation}
                        />
                      </div>
                    </div>

                    <div className="card my-3">
                      <div className="card-body">
                        <PurchaseCodesField
                          id="purchaseCodes"
                          name="purchaseCodes"
                          label={<h4>Group purchase codes</h4>}
                          errors={props.errors?.purchaseCodes}
                          touched={props.touched?.purchaseCodes}
                          help_text={
                            "If you have been given a purchase code, please enter that here. If you have not been given a purchase code, please leave this field blank."
                          }
                          lookupPurchaseCode={lookupPurchaseCode}
                          onChange={(codes) => {
                            setPurchaseCodes(codes);
                            loadAvailableProducts(codes);
                            codes
                              .map((code) => code.product_id)
                              .forEach(selectItem);
                          }}
                          disabled={!!props.isSubmitting}
                        />
                      </div>
                    </div>

                    <div className="card my-3">
                      <div className="card-body">
                        {calculateTotal(getSelectedItems()) === 0 ? (
                          <h4>Continue</h4>
                        ) : (
                          <h4>Checkout</h4>
                        )}
                        <div className="feedback-area">
                          {props.errors && props.touched && (
                            <div className="errors my-3">
                              {(props.errors?.__all__ || []).map((error) => {
                                return (
                                  <div key={error} className="validation-error">
                                    {error}
                                  </div>
                                );
                              })}
                              {props.errors?.__all__ && (
                                <div key="mailto" className="validation-error">
                                  Please try again later or email{" "}
                                  <a href={`mailto:${settings.supportEmail}`}>
                                    {settings.supportEmail}
                                  </a>{" "}
                                  for help.
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {calculateTotal(getSelectedItems()) === 0 ? (
                          <>
                            <div>
                              Click <em>Next</em> to continue without payment
                              and receive your free items.
                            </div>
                            <Button
                              type="submit"
                              variant="primary"
                              className="float-end"
                              autoComplete="off"
                              disabled={!!props.isSubmitting}
                            >
                              Next
                            </Button>
                          </>
                        ) : (
                          <>
                            <div className="pt-3">
                              <PayPalScriptProvider
                                options={{
                                  "client-id": settings.paypalClientId,
                                  intent: "authorize",
                                  "enable-funding": "venmo",
                                }}
                              >
                                <ButtonWrapper />
                              </PayPalScriptProvider>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default Checkout;
