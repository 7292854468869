import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const AuthenticatedRoute = ({ children }) => {
  const { userId } = useAuth();
  if (!userId) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

export default AuthenticatedRoute;
