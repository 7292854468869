import { useState } from "react";

import { Formik, Form } from "formik";
import Button from "react-bootstrap/Button";

import SurveyInputField from "../inputs/SurveyInputField";
import GroupField from "../inputs/GroupField";
import AsyncSelectField from "../inputs/AsyncSelectField";
import SelectField from "../inputs/SelectField";
import MultipleChoiceGridField from "../inputs/MultipleChoiceGridField";
import PhoneNumberField from "../inputs/PhoneNumberField";
import {
  isRequired,
  notRequired,
  validateEmail,
  validatePhone,
  validateMultipleChoiceGrid,
  validatePersonAgeYear,
} from "../../utils";

import useAuth from "../../hooks/useAuth";
import { useSurveyService } from "../../services/SurveyService";

import "./question.scss";

function getValidator(props) {
  let validators = [];

  (props.config.validators || []).forEach((validatorKey) => {
    if (validatorKey === "email") {
      validators.push(validateEmail("This email address is not valid."));
    } else if (validatorKey === "phone") {
      validators.push(validatePhone("This phone number is not valid."));
    } else if (validatorKey === "person-age-year-16") {
      validators.push(validatePersonAgeYear(16, 130));
    } else if (validatorKey === "person-age-year-0") {
      validators.push(validatePersonAgeYear(0, 130));
    }
  });

  if (props.required) {
    return isRequired("This question is required", ...validators);
  }

  return notRequired(...validators);
}

function getFormField(props) {
  if (props.config.type === "short-answer") {
    return (
      <SurveyInputField
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        requiredStyle={props.required}
        validate={getValidator(props)}
        className="form-control"
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
        placeholder={props.config.placeholder}
      />
    );
  } else if (
    props.config.type === "radio" ||
    props.config.type === "checkbox"
  ) {
    return (
      <GroupField
        type={props.config.type}
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        validate={getValidator(props)}
        requiredStyle={props.required}
        placeholder={props.config.placeholder}
        choices={props.config.choices}
        className="form-control"
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
      />
    );
  } else if (props.config.type === "select") {
    return (
      <SelectField
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        validate={getValidator(props)}
        requiredStyle={props.required}
        placeholder={props.config.placeholder}
        choices={props.config.choices}
        className="form-control"
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
      />
    );
  } else if (props.config.type === "async-select") {
    const getAdditionalFilters = async () => {
      const entries = Object.entries(props.config.searchContextLookup || {});

      // Build an array of promises that resolve to filter settings
      const answerPromises = entries.map(async ([filterKey, questionCode]) => {
        const answer = await props.getAnswer(props.responseId, questionCode);
        return [filterKey, answer];
      });

      // Wait for promises to resolve and filter out any undefined answers.
      // (undefined here probably indicates that the question hasn't been answered yet)
      const answers = (await Promise.all(answerPromises)).filter((item) => {
        return item[1] !== undefined;
      });

      // Return an object mapping filter names to their values
      return Object.fromEntries(answers);
    };

    return (
      <AsyncSelectField
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        validate={getValidator(props)}
        requiredStyle={props.required}
        placeholder={props.config.placeholder}
        choicesName={props.config.choicesName}
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
        getAdditionalFilters={getAdditionalFilters}
        getChoices={props.getChoices}
        defaultValue={props.defaultValue}
      />
    );
  } else if (props.config.type === "multiple-choice-grid") {
    return (
      <MultipleChoiceGridField
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        requiredStyle={props.required}
        className="form-control"
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
        rows={props.config.rows}
        columns={props.config.columns}
        oneResponsePerColumn={true}
        validate={
          props.required
            ? isRequired(
                "This question is required.",
                validateMultipleChoiceGrid(props.config.rows, true)
              )
            : notRequired(validateMultipleChoiceGrid(props.config.rows, true))
        }
      />
    );
  } else if (props.config.type === "phone") {
    return (
      <PhoneNumberField
        id={props.code}
        name={props.code}
        label={props.text}
        help_text={props.description}
        requiredStyle={props.required}
        validate={getValidator(props)}
        className="form-control"
        errors={props.errors[props.code]}
        touched={props.touched[props.code]}
        placeholder={props.config.placeholder}
        defaultValue={props.defaultValue}
      />
    );
  }
}

function Question(questionProps) {
  const authHandlers = useAuth();
  const { getAnswer, getChoices } = useSurveyService(authHandlers);
  const [failureMessage, setFailureMessage] = useState();
  let defaultValue;

  const doSubmitAnswer = (actions, answer) => {
    return questionProps
      .submitAnswer(questionProps.responseId, questionProps.id, answer)
      .then(() => {
        actions?.setSubmitting(false);
        questionProps.popQuestion();
      })
      .catch((response) => {
        actions?.setSubmitting(false);

        if (response.code === "already-answered") {
          questionProps.popQuestion();
          return;
        }

        if (response.status === "failure") {
          setFailureMessage(response.message);
        } else if (response.status === "error") {
          actions?.setFieldError(questionProps.code, response.errors?.value);
          setFailureMessage(
            (response.errors?.non_field_errors || []).join(",")
          );
        }
      });
  };

  const loadChoices = async (data) => {
    return getChoices(data).then((responseData) => {
      /* Handle situations in which there are no choices by auto-submitting the
       * question. */
      if (
        responseData.results.length === 0 &&
        questionProps.config.noChoicesSkip === true
      ) {
        doSubmitAnswer(undefined, "");
      }
      return responseData;
    });
  };

  if (!!questionProps.prefilled) {
    defaultValue = questionProps.prefilled;
  } else if (questionProps.config.type === "checkbox") {
    defaultValue = [];
  } else if (questionProps.config.type === "multiple-choice-grid") {
    defaultValue = {};
  } else {
    defaultValue = "";
  }

  return (
    <Formik
      key={questionProps.code}
      initialValues={{ [questionProps.code]: defaultValue }}
      onSubmit={async (formData, actions) => {
        actions.setFieldTouched(questionProps.code, true);
        actions.setSubmitting(true);
        setFailureMessage(); // Clear the failure message

        doSubmitAnswer(actions, formData[questionProps.code]);
      }}
    >
      {(props) => (
        <Form>
          <div className="survey-question-card card">
            <div className="card-body">
              <div
                className={
                  "mb-3 m-md-3 " +
                  (props.errors[questionProps.code] &&
                  props.touched[questionProps.code]
                    ? "errors"
                    : "")
                }
              >
                <label
                  key="label"
                  htmlFor={questionProps.code}
                  className={questionProps.required ? " required" : ""}
                >
                  <span className="label-text">{questionProps.text}</span>
                  <div>
                    <small>{questionProps.description}</small>
                  </div>
                </label>
                {getFormField({
                  ...questionProps,
                  ...props,
                  defaultValue: defaultValue,
                  getAnswer: getAnswer,
                  getChoices: loadChoices,
                })}
              </div>

              <div className="m-3">
                {failureMessage ? (
                  <div className="errors">{failureMessage}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="feedback-area m-3">
                <Button
                  type="submit"
                  variant="primary"
                  autoComplete="off"
                  disabled={!!props.isSubmitting || !!props.isValidating}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Question;
