import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

import { useSurveyService } from "../../../services/SurveyService";
import Response from "../response/Response";
import Spinner from "../../spinner/Spinner";

function Survey() {
  const [currentResponseId, setCurrentResponseId] = useState(undefined);
  const [status, setStatus] = useState("LOADING");
  const authHandlers = useAuth();
  const {
    createResponse,
    getIncompleteResponsesForSurvey,
    getIncompleteSurveys,
    submitResponse,
  } = useSurveyService(authHandlers);
  const { userId } = authHandlers;

  const loadNextResponse = async () => {
    let incompleteSurveys;
    let responses;
    let data;

    setStatus("LOADING");
    try {
      incompleteSurveys = await getIncompleteSurveys();
    } catch (errorData) {
      setStatus("ERROR");
      return;
    }

    if (incompleteSurveys?.results?.length === 0) {
      setStatus("FINISHED");
      return;
    }

    let surveyId = incompleteSurveys.results[0].id;

    try {
      responses = (await getIncompleteResponsesForSurvey(surveyId)).results;
    } catch (errorData) {
      setStatus("ERROR");
      return;
    }

    if (responses?.length > 0) {
      setCurrentResponseId(responses[0].id);
    } else if (responses?.length === 0) {
      try {
        data = await createResponse(surveyId, userId);
      } catch (errorData) {
        setStatus("ERROR");
        return;
      }
      setCurrentResponseId(data.id);
    } else {
      setStatus("ERROR");
      return;
    }

    setStatus("SURVEY");
  };

  const finishResponse = async () => {
    try {
      await submitResponse(currentResponseId);
    } catch (error) {
      setStatus("ERROR");
      return;
    }
    loadNextResponse();
  };

  useEffect(() => {
    loadNextResponse();
  }, []);

  if (status === "LOADING") {
    return <Spinner />;
  }

  if (status === "FINISHED") {
    return <Navigate replace to="/checkout" />;
  }

  if (status === "SURVEY") {
    return (
      <Response responseId={currentResponseId} callback={finishResponse} />
    );
  }

  // status is probably ERROR or unhandled
  return (
    <>
      Please wait a moment and refresh the page. If necessary, you can log back
      in and resume completing this survey later.
    </>
  );
}

export default Survey;
