function Product(props) {
  const buildPriceSection = (props) => {
    if (props.adjusted_price < props.adjusted_base_price) {
      return (
        <>
          <s className="gray-text">
            {parseFloat(props.adjusted_base_price).toFixed(2)} USD
          </s>
          <div>{parseFloat(props.adjusted_price).toFixed(2)} USD</div>
        </>
      );
    } else {
      return <div>{parseFloat(props.adjusted_price).toFixed(2)} USD</div>;
    }
  };

  return (
    <div className="product mx-1 my-3">
      <div>
        <em>{props.tagline}</em>
      </div>
      {buildPriceSection(props)}
      <div>{props.description}</div>
    </div>
  );
}

export default Product;
